<template>
  <div class="login-container">
    <el-row v-if="isPCflag">
      <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
        <div class="bg"></div>
      </el-col>
      <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12" class="rightPlane">
        <div>
          <div class="title">Hello !</div>
          <div class="title-tips">欢迎来到{{ customInfo.customTitle }}！</div>
          <div class="formContainer" v-if="!resetPass">
            <p class="formTitle">
              {{ scanCodeFlag ? '微信登录' : '账号登录' }}
            </p>
            <div class="scanCode" v-if="!scanCodeFlag">
              <el-tooltip placement="right">
                <template #content>
                  <div>微信扫码登录</div>
                </template>
                <i class="iconfont wemeet-erweima" style="font-size: 24px" @click="changeLoginType(1)"></i>
              </el-tooltip>
            </div>
            <div class="scanCode" v-if="scanCodeFlag">
              <el-tooltip placement="right">
                <template #content>
                  <div>账号密码登录</div>
                </template>
                <i class="iconfont wemeet-zhanghaodenglu" style="font-size: 24px" @click="changeLoginType(2)"></i>
              </el-tooltip>
            </div>
            <el-form ref="formRef" :model="form" :rules="rules" class="login-form" label-position="left" v-if="!scanCodeFlag">
              <el-form-item style="margin-top: 40px" prop="username">
                <span class="svg-container svg-container-admin">
                  <i class="iconfont wemeet-zhanghao"></i>
                </span>
                <el-input v-model.trim="form.username" v-focus placeholder="请输入用户名" tabindex="1" type="text" />
              </el-form-item>
              <el-form-item prop="password">
                <span class="svg-container">
                  <i class="iconfont wemeet-mima"></i>
                </span>
                <el-input show-password :key="passwordType" ref="passwordRef" v-model.trim="form.password" :type="passwordType" tabindex="2" placeholder="请输入密码" @keyup.enter.native="handleLogin" />
              </el-form-item>
              <el-form-item prop="phone">
                <span class="svg-container">
                  <i class="iconfont wemeet-zhanghaodenglu"></i>
                </span>
                <el-input v-model.trim="form.phone" v-focus placeholder="请输入手机号" tabindex="1" type="text">
                </el-input>
              </el-form-item>

              <el-form-item prop=" verificationCode">
                <span class="svg-container">
                  <i class="iconfont wemeet-yanzhengma"></i>
                </span>
                <el-input v-model.trim="form.phoneCaptchaCode" v-focus placeholder="请输入验证码" tabindex="1" type="text" @keyup.enter.native="handleLogin">
                  <template #append>
                    <!-- <el-image
                      class="code"
                      style="width: 100px; background: none; border: none"
                      :src="codeUrl"
                      @click="getCaptchaImageUrl"
                    /> -->
                    <el-button slot="append" @click="getLoginYZM">
                      {{ sendVerifyCode1 ? countTime1 : '获取验证码' }}
                    </el-button>
                  </template>
                </el-input>
              </el-form-item>
              <el-form-item>
                <span class="forgetPass" @click="resetPass = true">
                  忘记密码?
                </span>
              </el-form-item>
              <el-form-item>
                <el-button :loading="loading" class="login-btn" type="primary" @click="handleLogin">
                  登录
                </el-button>
              </el-form-item>
            </el-form>

            <div class="qrCodeBox" v-if="scanCodeFlag">
              <el-image :src="qrCodeByWeChart" :preview-src-list="[qrCodeByWeChart]" style="width: 260px; height: 260px">
                <div slot="placeholder" class="image-slot">加载中</div>
              </el-image>
              <p style="font-size: 14px; font-weight: 400; color: #999999">
                打开微信APP扫一扫扫码即可登录
              </p>
            </div>
          </div>

          <div class="formContainer" v-if="resetPass">
            <p class="formTitle">重置密码</p>
            <el-form label-position="left" class="login-form" :model="resetPassForm" ref="resetPassFormRef">
              <el-form-item>
                <span class="svg-container">
                  <i class="iconfont wemeet-mima"></i>
                </span>
                <el-input v-model.trim="resetPassForm.password" show-password placeholder="请输入新密码" />
              </el-form-item>
              <el-form-item>
                <span class="svg-container">
                  <i class="iconfont wemeet-mima"></i>
                </span>
                <el-input v-model.trim="resetPassForm.secondPass" show-password placeholder="请确认密码" />
              </el-form-item>
              <el-form-item prop="phone">
                <span class="svg-container svg-container-admin">
                  <vab-icon icon="smartphone-line" />
                </span>
                <el-input v-model="resetPassForm.phone" placeholder="请输入手机号" />
              </el-form-item>
              <el-form-item prop="validCode">
                <span class="svg-container svg-container-admin">
                  <i class="iconfont wemeet-yanzhengma"></i>
                </span>
                <el-input v-model="resetPassForm.validCode" placeholder="请输入验证码">
                  <template #append>
                    <el-button slot="append" @click="getYZM">
                      {{ sendVerifyCode ? countTime : '获取验证码' }}
                    </el-button>
                  </template>
                </el-input>
              </el-form-item>

              <el-form-item>
                <el-button type="primary" @click="resetPassFunc" class="resetPassBtn">
                  重置密码
                </el-button>
                <el-button type="text" @click="resetPass = false" style="font-size: 16px; font-weight: 400; color: #6e9cf3">
                  返回登录
                </el-button>
              </el-form-item>
            </el-form>
          </div>
        </div>
      </el-col>
    </el-row>
    <div class="mobile-login-container" v-else>
      <div class="login-box">
        <div class="title">Hello !</div>
        <div class="title-tips">欢迎来到{{ customInfo.customTitle }}！</div>
        <el-form ref="formRef" :model="form" :rules="rules" class="login-form" label-position="left" v-if="!scanCodeFlag">
          <el-form-item style="margin-top: 40px" prop="username">
            <span class="svg-container svg-container-admin">
              <i class="iconfont wemeet-zhanghao"></i>
            </span>
            <el-input v-model.trim="form.username" v-focus placeholder="请输入用户名" tabindex="1" type="text" />
          </el-form-item>
          <el-form-item prop="password">
            <span class="svg-container">
              <i class="iconfont wemeet-mima"></i>
            </span>
            <el-input show-password :key="passwordType" ref="passwordRef" v-model.trim="form.password" :type="passwordType" tabindex="2" placeholder="请输入密码" @keyup.enter.native="handleLogin" />
          </el-form-item>
          <el-form-item prop="verificationCode">
            <span class="svg-container">
              <i class="iconfont wemeet-yanzhengma"></i>
            </span>
            <el-input v-model.trim="form.captchaCode" v-focus placeholder="请输入验证码" tabindex="1" type="text" @keyup.enter.native="handleLogin">
              <template #append>
                <el-image class="code" style="width: 100px; background: none; border: none" :src="codeUrl" @click="getCaptchaImageUrl" />
              </template>
            </el-input>
          </el-form-item>
          <!-- <el-form-item>
            <span class="forgetPass" @click="resetPass = true">忘记密码?</span>
          </el-form-item> -->
          <el-form-item>
            <el-button :loading="loading" class="login-btn" type="primary" @click="handleLogin">
              登录
            </el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import {
  computed,
  defineComponent,
  nextTick,
  onBeforeMount,
  reactive,
  toRefs,
  getCurrentInstance,
  watchEffect,
  watch,
} from 'vue'
import { useStore } from 'vuex'
import { onBeforeRouteLeave, useRoute, useRouter } from 'vue-router'
import { translateTitle } from '@/utils/i18n'
import { isPassword, isPhone } from '@/utils/validate'
import { getCaptchaImage } from '@/api/login'
import { isPC } from '@/utils/index'
import {
  getValidCodeMsg,
  resetPassword,
  generateLoginUrl,
  wxLogin,
} from '@/api/user'

import { gp } from '@vab'
import { version } from '../../../package.json'
export default defineComponent({
  name: 'Login',
  directives: {
    focus: {
      mounted(el) {
        el.querySelector('input').focus()
      },
    },
  },
  setup() {
    const { proxy } = getCurrentInstance()
    const store = useStore()
    const route = useRoute()
    const router = useRouter()

    const login = (form) => store.dispatch('user/login', form)
    const userInfo = (data) => store.dispatch('user/getUserInfo', data)

    const wxLoginStore = (data) => store.dispatch('user/wxlogin', data)

    const getCustomInfo = (tenantKey) => {
      store.dispatch('user/getCustomInfo', { tenantKey })
      document.title = '登录页'
    }

    const validateUsername = (rule, value, callback) => {
      if ('' === value) callback(new Error(translateTitle('用户名不能为空')))
      else callback()
    }
    const validatePassword = (rule, value, callback) => {
      if (!isPassword(value))
        callback(new Error(translateTitle('密码不能少于6位')))
      else callback()
    }

    const state = reactive({
      formRef: null,
      resetPassFormRef: null,
      scanCodeFlag: false,
      isPCflag: isPC(),
      form: {
        username: '',
        password: '',
        requestId: '',
        phoneCaptchaCode: '',
        phone: '',
      },
      resetPassForm: {
        password: '',
        secondPass: '',
        phone: '',
        validCode: '',
      },
      rules: {
        username: [
          {
            required: true,
            trigger: 'blur',
            validator: validateUsername,
          },
        ],
        password: [
          {
            required: true,
            trigger: 'blur',
            validator: validatePassword,
          },
        ],
        phone: [
          {
            required: true,
            trigger: 'blur',
            message: '手机号不能空',
          },
        ],
        phoneCaptchaCode: [
          {
            required: true,
            trigger: 'blur',
            message: '验证码不能空',
          },
        ],
      },
      loading: false,
      passwordType: 'password',
      redirect: undefined,
      tenantKey: undefined,
      timer: 0,
      requestId: '',
      codeUrl: '',
      previewText: '',
      resetPass: false,
      sendVerifyCode: false,
      sendVerifyCode1: false,
      countTime: '',
      countTime1: '',
      uuid: '',
      qrCodeByWeChart: '',
      wxLoginInterval: null,
    })

    const handleRoute = () => {
      return state.redirect === '/404' || state.redirect === '/403'
        ? '/index'
        : state.redirect
    }
    const handlePassword = () => {
      state.passwordType === 'password'
        ? (state.passwordType = '')
        : (state.passwordType = 'password')
      nextTick(() => {
        state['passwordRef'].focus()
      })
    }

    /**
     * @description: 切换登录方式
     * @param {*} type
     * @return {*}
     */
    const changeLoginType = (type) => {
      if (type == 1) {
        state.scanCodeFlag = true
        loginWx()
      } else {
        state.scanCodeFlag = false
        clearInterval(state.wxLoginInterval)
      }
    }

    /**
     * @description: 获取微信登录二维码
     * @return {*}
     */

    const loginWx = () => {
      generateLoginUrl({ tenantKey: state.tenantKey })
        .then((res) => {
          state.qrCodeByWeChart = res.data.qrCodeUrl
          state.uuid = res.data.uuid
          wxLoginFunc()
        })
        .catch((err) => {
          // this.$baseMessage('微信登录无效，请用账号密码登录', 'error')
        })
    }

    const wxLoginFunc = async () => {
      state.wxLoginInterval = setInterval(async () => {
        try {
          const { data } = await wxLogin({
            uuid: state.uuid,
            tenantKey: state.tenantKey,
          })
          if (data) {
            clearInterval(state.wxLoginInterval)
            await wxLoginStore(data).catch((e) => {})
            await router.push(handleRoute())
          }
        } catch (e) {
          console.log(e)
          clearInterval(state.wxLoginInterval)
        }
      }, 1000)
    }

    /**
     * @description: 获取验证码
     * @return {*}
     */
    const getYZM = () => {
      if (state.sendVerifyCode) {
        return false
      }
      if (!isPhone(state.resetPassForm.phone)) {
        proxy.$baseMessage(`手机号不合法`, 'error')
        return false
      }
      state.sendVerifyCode = true
      state.countTime = `60秒后重新获取`
      getValidCodeMsg({ phone: state.resetPassForm.phone })
        .then((res) => {
          let timeDown = 60
          var countDownTimeInterval = setInterval(() => {
            timeDown--
            state.countTime = `${timeDown}秒后重新获取`
            if (timeDown <= 0) {
              clearInterval(countDownTimeInterval)
              state.sendVerifyCode = false
            }
          }, 1000)
        })
        .catch((err) => {
          state.sendVerifyCode = false
        })
    }

    const getLoginYZM = () => {
      if (state.sendVerifyCode1) {
        return false
      }
      if (!isPhone(state.form.phone)) {
        proxy.$baseMessage(`手机号不合法`, 'error')
        return false
      }
      state.sendVerifyCode1 = true
      state.countTime1 = `60秒后重新获取`
      getValidCodeMsg({ phone: state.form.phone })
        .then((res) => {
          let timeDown = 60
          var countDownTimeInterval = setInterval(() => {
            timeDown--
            state.countTime1 = `${timeDown}秒后重新获取`
            if (timeDown <= 0) {
              clearInterval(countDownTimeInterval)
              state.sendVerifyCode1 = false
            }
          }, 1000)
        })
        .catch((err) => {
          state.sendVerifyCode1 = false
        })
    }

    /**
     * @description: 重置密码
     * @return {*}
     */
    const resetPassFunc = () => {
      state['resetPassFormRef'].validate(async (valid) => {
        if (valid) {
          if (
            !state.resetPassForm.password ||
            !state.resetPassForm.secondPass
          ) {
            proxy.$baseMessage(`密码不得为空`, 'error')
            return false
          }
          if (state.resetPassForm.password != state.resetPassForm.secondPass) {
            proxy.$baseMessage(`两次密码不一致，请确认后重试`, 'error')
            return false
          }
          if (!isPhone(state.resetPassForm.phone)) {
            proxy.$baseMessage(`手机号格式有误`, 'error')
            return false
          }
          if (!state.resetPassForm.validCode) {
            proxy.$baseMessage(`验证码不得为空`, 'error')
            return false
          }
          const { msg } = await resetPassword(state.resetPassForm)
          proxy.$baseMessage(msg, 'success')
        } else {
          return false
        }
      })
    }

    const handleLogin = async () => {
      state['formRef'].validate(async (valid) => {
        if (valid)
          try {
            state.loading = true
            state.form.tenantKey = state.tenantKey
            await login(state.form)
              .then(async (res) => {
                await router.push(handleRoute())
              })
              .catch((e) => {
                // if (e.code == 2002) {

                // }
                getCaptchaImageUrl()
              })
          } finally {
            //  getCaptchaImageUrl()
            state.loading = false
          }
      })
    }
    onBeforeMount(() => {
      getCaptchaImageUrl()
      getCustomInfo(state.tenantKey)

      const localVersion = localStorage.getItem('version')
      console.log(version, localVersion, version == localVersion)
      if (version != localVersion) {
        localStorage.setItem('version', version)
        gp.$baseNotify(
          '更新版本完成，3S后刷新项目',
          '温馨提示',
          'success',
          'bottom-right',
          2000
        )
        setTimeout(() => {
          window.location.reload(true)
        }, 3000)
      }
      // console.log(version)
    })

    watchEffect(() => {
      state.redirect = (route.query && route.query.redirect) || '/index'
      state.tenantKey = (route.query && route.query.tenantKey) || '/index'
    })
    watch(
      () => route.query.tenantKey,
      (newVal, oldVal) => {
        if (!!newVal) {
          getCustomInfo(newVal)
        }
      }
    )

    onBeforeRouteLeave((to, from, next) => {
      clearInterval(state.timer)
      next()
    })

    //获取验证码
    const getCaptchaImageUrl = () => {
      getCaptchaImage().then((res) => {
        state.codeUrl = 'data:image/gif;base64,' + res.data.base64Img
        state.form.requestId = res.data.requestId
      })
    }
    return {
      translateTitle,
      ...toRefs(state),
      title: computed(() => store.getters['settings/title']),
      customInfo: computed(() => store.getters['user/customInfo']),
      handlePassword,
      handleLogin,
      getCaptchaImageUrl,
      changeLoginType,
      resetPassFunc,
      getYZM,
      getLoginYZM,
    }
  },
})
</script>

<style lang="scss" scoped>
.login-container {
  .bg {
    width: 100%;
    height: 100vh;
    background: url('https://wemeet-1302608036.cos.ap-shanghai.myqcloud.com/WeMeetStatic/adminLoginBg.png')
      center center/cover no-repeat;
  }
  .rightPlane {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background: #e9f7f6;
  }
  .title {
    font-size: 36px;
    font-weight: 600;
    color: #000000;
    text-indent: 1em;
  }
  .title-tips {
    font-size: 20px;
    font-weight: 400;
    color: #000000;
    text-indent: 2em;
  }

  .formContainer {
    position: relative;
    width: 546px;
    min-height: 510px;
    padding: 30px;
    margin-top: 27px;
    text-align: center;
    background: #ffffff;
    border-radius: 20px;
    .formTitle {
      font-size: 22px;
      font-weight: 600;
      color: #000000;
    }
    .scanCode {
      position: absolute;
      top: 30px;
      left: 30px;
      cursor: pointer;
      .tips {
        position: absolute;
        top: 5px;
        left: 30px;
        width: 90px;
        height: 30px;
        font-size: 12px;
        font-weight: 400;
        line-height: 30px;
        color: #333333;
        text-align: center;
        background: #ffffff;
        box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.1);
      }
    }
  }

  .login-btn {
    display: inline-block;
    width: 100%;
    height: 60px;
    margin-top: 5px;
    font-size: 20px;
    font-weight: 400;
    color: #ffffff;
    background: #4aa3a8 !important;
    border: 0;
    &:hover {
      opacity: 0.9;
    }
  }

  .resetPassBtn {
    display: inline-block;
    width: 200px;
    height: 60px;
    margin-top: 5px;
    font-size: 20px;
    font-weight: 400;
    color: #ffffff;
    background: #4aa3a8 !important;
    border: 0;
    &:hover {
      opacity: 0.9;
    }
  }

  .forgetPass {
    float: right;
    font-size: 16px;
    font-weight: 400;
    color: #6e9cf3;
    cursor: pointer;
  }

  .login-form {
    position: relative;
    box-sizing: border-box;
    max-width: 100%;
    margin-top: 27px;
    .forget-password {
      width: 100%;
      margin-top: 40px;
      text-align: left;

      .forget-pass {
        width: 129px;
        height: 19px;
        font-size: 20px;
        font-weight: 400;
        color: rgba(92, 102, 240, 1);
      }
    }
  }

  .tips {
    margin-bottom: 10px;
    font-size: $base-font-size-default;
    color: $base-color-white;

    span {
      &:first-of-type {
        margin-right: 16px;
      }
    }
  }

  .title-container {
    position: relative;

    .title {
      margin: 0 auto 40px auto;
      font-size: 36px;
      font-weight: 600;
      color: #000000;
    }
  }

  .svg-container {
    position: absolute;
    top: 14px;
    left: 15px;
    z-index: $base-z-index;
    font-size: 16px;
    color: #d7dee3;
    cursor: pointer;
    user-select: none;
  }

  .show-password {
    position: absolute;
    top: 14px;
    right: 25px;
    font-size: 16px;
    color: #d7dee3;
    cursor: pointer;
    user-select: none;
  }

  ::v-deep {
    .el-form-item {
      padding-right: 0;
      margin: 30px 0;
      color: #454545;
      background: transparent;
      border: 1px solid transparent;
      border-radius: 2px;

      &__content {
        min-height: $base-input-height;
        line-height: $base-input-height;
      }

      &__error {
        position: absolute;
        top: 100%;
        left: 18px;
        font-size: $base-font-size-small;
        line-height: 18px;
        color: var(--el-color-error);
      }
    }

    .el-input {
      box-sizing: border-box;
      .el-input-group__append {
        background: none;
        border: none;
      }
      input {
        height: 58px;
        padding-left: 45px;
        font-size: $base-font-size-default;
        line-height: 58px;
        color: #606266;
        border: 1px solid #e2e2e2;
        border-radius: 8px;
        caret-color: #606266;
      }
    }
  }
}
.mobile-login-container {
  box-sizing: border-box;
  width: 100%;
  height: 100vh;
  background: url('https://wemeet-1302608036.cos.ap-shanghai.myqcloud.com/WeMeetStatic/adminLoginBg.png')
    top center/contain no-repeat;
  .login-box {
    position: fixed;
    bottom: 0;
    padding: 20px;
    .title {
      font-size: 36px;
      font-weight: 600;
      color: #000000;
      text-indent: 20px;
    }
    .title-tips {
      margin-top: 20px;
      font-size: 20px;
      font-weight: 400;
      color: #000000;
      text-indent: 20px;
    }
  }
}
</style>
